import React, { useState, useEffect } from 'react';
import { NumericFormat } from 'react-number-format';
import { DataTable } from '@/components/ui/datatable/data-table';
import { Button } from '@/components/ui/button';
import InvoiceDetailView from './invoiceDetailView';
import InvoiceCreate from './invoiceCreate';
import { getInvoiceSummaryInvoiceInvoiceSummariesGet, getInvoiceCustomersGet } from '@/client/services.gen';
import { wrapperFunction } from '@/client/wrapperFunction';

interface Invoice {
    customer_name: string;
    invoice_id: string;
    billing_period_start: string;
    billing_period_end: string;
    total_invoice_amount: number;
}

interface Customer {
    label: string;
    value: string;
}

const invoiceColumns = [
    {
        accessorKey: 'customer_name',
        header: 'Customer',
        cell: ({ row }: { row: any }) => (
            <span className="w-[200px] font-medium hover:underline">
                <Button variant="link" className="text-black">
                    {row.original.customer_name}
                </Button>
            </span>
        ),
        enableSorting: true,
    },
    {
        accessorKey: 'invoice_id',
        header: 'Invoice ID',
        cell: ({ row }: { row: any }) => (
            <span className="max-w-[500px] text-wrap">{row.original.invoice_id}</span>
        ),
        enableSorting: false,
    },
    {
        accessorKey: 'billing_period_start',
        header: 'Billing Period Start',
        cell: ({ row }: { row: any }) => (
            <span className="max-w-[500px] text-wrap">{row.getValue('billing_period_start').substring(0, 10)}</span>
        ),
        enableSorting: true,
    },
    {
        accessorKey: 'billing_period_end',
        header: 'Billing Period End',
        cell: ({ row }) => (
            <div className="flex flex-wrap space-x-2">
                <span className="max-w-[500px] text-wrap">{row.getValue('billing_period_end').substring(0, 10)}</span>
            </div>
        ),
        enableSorting: true,
    },
    {
        accessorKey: 'total_invoice_amount',
        header: 'Total Invoice Amount',
        cell: ({ row }) => (
            <div className="flex flex-wrap space-x-2">
                <span className="max-w-[500px] text-wrap">
                    <NumericFormat 
                        value={row.getValue('total_invoice_amount')} 
                        displayType="text" 
                        thousandSeparator="," 
                        prefix="$" 
                        decimalScale={2} 
                        fixedDecimalScale 
                    />
                </span>
            </div>
        ),
        enableSorting: true,
    },
];

const InvoiceDetailViewComponent: React.FC<{
    invoiceOpen: boolean;
    setInvoiceOpen: React.Dispatch<React.SetStateAction<boolean>>;
    selectedInvoice: Invoice | null;
}> = ({ invoiceOpen, setInvoiceOpen, selectedInvoice }) => (
    <InvoiceDetailView open={invoiceOpen} setOpen={setInvoiceOpen} invoiceData={selectedInvoice} />
);

interface InvoiceOverviewProps {
    filter?: any;
    dataTableOnly?: boolean;
}

const InvoiceOverview: React.FC<InvoiceOverviewProps> = ({ filter = null, dataTableOnly = false }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [invoiceTableData, setInvoiceTableData] = useState<Invoice[]>([]);
    const [customerData, setCustomerData] = useState<Customer[]>([]);
    const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null);
    const [invoiceOpen, setInvoiceOpen] = useState<boolean>(false);

    const fetchCustomers = async () => {
        await wrapperFunction(getInvoiceCustomersGet, {}).then((res) => {
            const filteredData = res
                .filter((customer: any) => customer.active === true)
            .filter((customer: any) => customer.invoice_customer_id === null)
            .map((item: any) => ({
                label: item.name,
                value: item.id,
            }));
            setCustomerData(filteredData);
        });
    };

    useEffect(() => {
        setIsLoading(true);
        Promise.all([wrapperFunction(getInvoiceSummaryInvoiceInvoiceSummariesGet), fetchCustomers()]).then((res) => {
            const sortedData = res[0].sort((a: Invoice, b: Invoice) => {
                const dateA = new Date(a.billing_period_end);
                const dateB = new Date(b.billing_period_end);
                if (!isNaN(dateA.getTime()) && !isNaN(dateB.getTime())) {
                    return dateB.getTime() - dateA.getTime();
                }
                return 0;
            });
            setInvoiceTableData(sortedData);
            setIsLoading(false);
        });
    }, []);

    const handleRowClick = (row: any) => {
        const selectedInvoiceItem = invoiceTableData.find((data) => data.invoice_id === row.original.invoice_id);
        setSelectedInvoice(selectedInvoiceItem || null);
        setInvoiceOpen(true);
    };

    return (
        <div className="container mx-auto p-8">
            {invoiceTableData && (
                <DataTable
                    loading={isLoading}
                    data={invoiceTableData}
                    columns={invoiceColumns}
                    rowOnClick={handleRowClick}
                    ActionButton={<InvoiceCreate customerData={customerData} />}
                    isMultiSelectRows={false}
                    showActionButtonInToolbar
                    isDownloadable={true}
                    downloadData={invoiceTableData.map((invoice) => ({
                        invoice_id: invoice.invoice_id,
                        customer_name: invoice.customer_name,
                        billing_period_start: invoice.billing_period_start,
                        billing_period_end: invoice.billing_period_end,
                        total_invoice_amount: invoice.total_invoice_amount,
                    }))}
                    tableName="invoices"
                />
            )}
            <InvoiceDetailViewComponent invoiceOpen={invoiceOpen} setInvoiceOpen={setInvoiceOpen} selectedInvoice={selectedInvoice} />
        </div>
    );
};

export default InvoiceOverview;

import React, { useState, useEffect } from 'react';
import { Plus } from 'lucide-react';
import { NumericFormat } from 'react-number-format';
import { useParams } from 'react-router-dom';
import { DataTable } from '@/components/ui/datatable/data-table';
import { Button } from '@/components/ui/button';
import { getInvoiceDetailInvoiceInvoiceDetailInvoiceIdGet, getInvoiceChargeTypesGet, postInvoiceLineitemsPost, updateLineItemAmountManuallyInvoiceLineitemsInvoiceIdLineItemIdPut } from '@/client/services.gen';
import { wrapperFunction } from '@/client/wrapperFunction';
import * as z from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Input } from '@/components/common/input';
import { Form, FormField, FormItem, FormControl, FormMessage, FormLabel } from '@/components/common/form';
import Select from 'react-select';
import LoadingSpinner from '@/components/common/loadingSpinner';

interface ChargeType {
    label: string;
    value: string;
    charge_level: string;
}

interface InvoiceCharge {
    line_item_id: string;
    description: string | null;
    charge_type_name: string;
    quantity: number;
    amount: number;
}

interface NonInvoiceLevelData {
    order_id: string;
    package_id: string;
    order_number: string;
    charges: InvoiceCharge[];
}

const invoiceChargeSchema = z.object({
    charge_type_id: z.object({
        label: z.string(),
        value: z.string(),
    }),
    amount: z.string().min(1, { message: 'Required' }),
    total_quantity: z.string().min(1, { message: 'Required' }),
    description: z.string().min(1, { message: 'Required' }),
});

const editInvoiceChargeSchema = z.object({
    charge_type_name: z.string().optional(),
    old_amount: z.string(),
    amount: z.number().min(0, { message: 'Required' }),
});

type InvoiceChargeSchema = z.infer<typeof invoiceChargeSchema>;
type EditInvoiceChargeSchema = z.infer<typeof editInvoiceChargeSchema>;

const SingleInvoiceDetail: React.FC = () => {
    const { invoiceId } = useParams<{ invoiceId: string }>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [nonInvoiceLevelData, setNonInvoiceLevelData] = useState<NonInvoiceLevelData[]>([]);
    const [invoiceLevelCharges, setInvoiceLevelCharges] = useState<InvoiceCharge[]>([]);
    const [selectedCharge, setSelectedCharge] = useState<InvoiceCharge | null>(null);
    const [selectedOrder, setSelectedOrder] = useState<NonInvoiceLevelData | null>(null);
    const [isEditChargeOpen, setIsEditChargeOpen] = useState<boolean>(false);
    const [openAddInvoiceLevelCharge, setOpenAddInvoiceLevelCharge] = useState<boolean>(false);
    const [openAddNonInvoiceLevelCharge, setOpenAddNonInvoiceLevelCharge] = useState<boolean>(false);
    const [invoiceChargeTypes, setInvoiceChargeTypes] = useState<ChargeType[]>([]);

    const formProps = useForm<InvoiceChargeSchema>({
        resolver: zodResolver(invoiceChargeSchema),
        defaultValues: {
            charge_type_id: { label: '', value: '' },
            amount: '',
            total_quantity: '',
            description: '',
        },
    });

    const editFormProps = useForm<EditInvoiceChargeSchema>({
        resolver: zodResolver(editInvoiceChargeSchema),
        defaultValues: {
            charge_type_name: selectedCharge?.charge_type_name || '',
            old_amount: selectedCharge?.amount?.toString() || '0',
            amount: selectedCharge?.amount || 0,
        },
    });

    const { handleSubmit, reset, control } = formProps;
    const { handleSubmit: handleEditSubmit, reset: resetEdit, control: controlEdit } = editFormProps;

    const openEditForm = (row: { original: InvoiceCharge }) => {
        setSelectedCharge(row.original);
        resetEdit({
            charge_type_name: row.original.description,
            old_amount: row.original.amount.toString(),
            amount: row.original.amount,
        });
        setIsEditChargeOpen(true);
    };

    const openAddNonInvoiceLevelChargeForm = (row: NonInvoiceLevelData) => {
        setSelectedOrder(row);
        setOpenAddNonInvoiceLevelCharge(true);
    };

    const invoiceColumns = [
        {
            accessorKey: 'order_number',
            header: "Order Number",
            cell: ({ row }: { row: any }) => <div className="w-[200px] font-medium">{row.getValue('order_number')}</div>,
            enableSorting: true,
            enableHiding: false,
        },
    ];

    const invoiceLevelChargeColumns = [
        {
            accessorKey: 'description',
            header: "Charge",
            cell: ({ row }: { row: any }) => {
                const { description } = row.original;
                const chargeTypeName = row.original.charge_type_name;
                const displayText = description === null ? chargeTypeName : description;

                return (
                    <div className="w-[200px] font-medium hover:underline" onClick={() => openEditForm(row)}>
                        <a href="#">{displayText}</a>
                    </div>
                );
            },
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'quantity',
            header: "Quantity",
            cell: ({ row }: { row: any }) => (
                <div className="flex flex-wrap space-x-2">
                    <span className="max-w-[500px] text-wrap">
                        <NumericFormat value={row.getValue('quantity')} displayType="text" thousandSeparator="," decimalScale={0} fixedDecimalScale />
                    </span>
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'amount',
            header: "Charge",
            cell: ({ row }: { row: any }) => (
                <div className="flex flex-wrap space-x-2">
                    <span className="max-w-[500px] text-wrap">
                        <NumericFormat value={row.getValue('amount')} displayType="text" thousandSeparator="," prefix="$" decimalScale={2} fixedDecimalScale />
                    </span>
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
    ];

    const chargeColumns = [
        {
            accessorKey: 'description',
            header: "Charge Name",
            cell: ({ row }: { row: any }) => (
                <div className="w-[200px] font-medium hover:underline" onClick={() => openEditForm(row)}>
                    <a href="#">{row.getValue('description')}</a>
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'quantity',
            header: "Quantity",
            cell: ({ row }: { row: any }) => (
                <div className="flex flex-wrap space-x-2">
                    <span className="max-w-[500px] text-wrap">
                        <NumericFormat value={row.getValue('quantity')} displayType="text" thousandSeparator="," decimalScale={0} fixedDecimalScale />
                    </span>
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
        {
            accessorKey: 'amount',
            header: "Total Invoice Amount",
            cell: ({ row }: { row: any }) => (
                <div className="flex flex-wrap space-x-2">
                    <span className="max-w-[500px] text-wrap">
                        <NumericFormat value={row.getValue('amount')} displayType="text" thousandSeparator="," prefix="$" decimalScale={2} fixedDecimalScale />
                    </span>
                </div>
            ),
            enableSorting: true,
            enableHiding: false,
        },
    ];

    const fetchChargeTypes = async () => {
        try {
            const res = await wrapperFunction(getInvoiceChargeTypesGet, {});
            const filteredData = res
                .filter((item: any) => !item.name.toLowerCase().includes('special rule'))
                .map((item: any) => ({ label: item.name, value: item.id, charge_level: item.charge_level }));
            setInvoiceChargeTypes(filteredData);
        } catch (error) {
            console.error('Error fetching charge types:', error);
        }
    };

    const fetchInvoiceDetails = async () => {
        try {
            const output = await wrapperFunction(getInvoiceDetailInvoiceInvoiceDetailInvoiceIdGet, { invoiceId });
            setNonInvoiceLevelData(output.package_charges || []);

            const normalizeCharge = (charge: any, type: string) => ({
                ...charge,
                description: type === 'product' ? charge.name : charge.description,
                charge_type_name: type,
            });

            const combinedCharges = [
                ...(output.product_charges || []).map((charge: any) => normalizeCharge(charge, 'product')),
                ...(output.manual_charges || []).map((charge: any) => normalizeCharge(charge, 'manual')),
                ...(output.storage_charges || []).map((charge: any) => normalizeCharge(charge, 'storage')),
            ];
            setInvoiceLevelCharges(combinedCharges);
        } catch (error) {
            console.error('Error fetching invoice details:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setIsLoading(true);

        if (invoiceId) {
            fetchChargeTypes();
            fetchInvoiceDetails();
        }
    }, [invoiceId]);

    const onSubmit = async (values: InvoiceChargeSchema, isInvoiceLevel: boolean) => {
        setIsLoading(true);
        try {
            const selectedChargeType = invoiceChargeTypes.find((type) => type.value === values.charge_type_id.value);

            const payload: any = {
                amount: parseFloat(values.amount),
                quantity: parseFloat(values.total_quantity),
                charge_type_id: values.charge_type_id.value,
                invoice_id: invoiceId,
                description: values.description,
            };

            if (!isInvoiceLevel && selectedChargeType && selectedOrder) {
                if (selectedChargeType.charge_level === 'order_level') {
                    payload.order_id = selectedOrder.order_id;
                } else if (selectedChargeType.charge_level === 'package_level') {
                    payload.package_id = selectedOrder.package_id;
                }
            }

            await wrapperFunction(postInvoiceLineitemsPost, { requestBody: payload });
            await fetchInvoiceDetails();
            
            setOpenAddInvoiceLevelCharge(false);
            setOpenAddNonInvoiceLevelCharge(false);
        } catch (error) {
            console.error('Error adding charge:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const onEditSubmit = async (values: EditInvoiceChargeSchema) => {
        setIsLoading(true);
        try {
            if (invoiceId && selectedCharge) {
                await wrapperFunction(
                    updateLineItemAmountManuallyInvoiceLineitemsInvoiceIdLineItemIdPut,
                    { invoiceId, lineItemId: selectedCharge.line_item_id, amount: values.amount }
                );
                await fetchInvoiceDetails();
                setIsEditChargeOpen(false);
            }
        } catch (error) {
            console.error('Error updating charge:', error);
        } finally {
            setIsLoading(false);
        }
    };

    // Reset form when dialog is opened
    useEffect(() => {
        if (openAddInvoiceLevelCharge || openAddNonInvoiceLevelCharge) {
            reset();
        }
    }, [openAddInvoiceLevelCharge, openAddNonInvoiceLevelCharge, reset]);

    const DataTableComponent: React.FC = () => (
        <DataTable
            loading={isLoading}
            data={nonInvoiceLevelData}
            columns={invoiceColumns}
            collapsible
            ActionButton={null}
            showActionButtonInToolbar
            showActionButtonInCollapsible
            collapsibleContent={({ row }: { row: any }) => {
                if (row?.charges) {
                    return (
                        <DataTable
                            loading={isLoading}
                            data={row.charges}
                            columns={chargeColumns}
                            isMultiSelectRows={false}
                            showPagination={false}
                            showActionButtonInToolbar
                            showActionButtonInCollapsible
                            collapsible={false}
                            ActionButton={
                                <Button className="p-4" onClick={() => openAddNonInvoiceLevelChargeForm(row)}>
                                    <Plus className="h-4 w-4 mr-2" />
                                    Add Charge
                                </Button>
                            }
                        />
                    );
                }
                return null;
            }}
            isMultiSelectRows={false}
            tableName="invoices"
        />
    );

    const DataTableComponentInvoiceLevelCharges: React.FC = () => (
        <DataTable
            loading={isLoading}
            data={invoiceLevelCharges}
            columns={invoiceLevelChargeColumns}
            ActionButton={
                <Button
                    className="p-4"
                    onClick={() => setOpenAddInvoiceLevelCharge(true)}
                >
                    <Plus className="h-4 w-4 mr-2" />
                    Add Charge
                </Button>
            }
            showActionButtonInToolbar
            showActionButtonInCollapsible
            isMultiSelectRows={false}
            tableName="invoices"
        />
    );

    const AddChargeForm: React.FC<{ isInvoiceLevel: boolean }> = ({ isInvoiceLevel }) => (
        <Form {...formProps}>
            <form onSubmit={handleSubmit((values) => onSubmit(values, isInvoiceLevel))} className="space-y-8">
                <FormField
                    control={control}
                    name="charge_type_id"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Charge Name on Invoice</FormLabel>
                            <FormControl>
                                <Select
                                    {...field}
                                    options={invoiceChargeTypes.filter(type => 
                                        isInvoiceLevel ? type.charge_level === 'invoice_level' : type.charge_level !== 'invoice_level'
                                    )}
                                    placeholder="Charge Category"
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#DBF3D8',
                                            primary: '#92BAA3',
                                        },
                                    })}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={control}
                    name="amount"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Total Charge Amount</FormLabel>
                            <FormControl>
                                <Input 
                                    className="mt-2" 
                                    type="number" 
                                    step="0.01" 
                                    {...field} 
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={control}
                    name="total_quantity"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Total Quantity</FormLabel>
                            <FormControl>
                                <Input 
                                    className="mt-2" 
                                    type="number" 
                                    step="1" 
                                    {...field} 
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={control}
                    name="description"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Description</FormLabel>
                            <FormControl>
                                <Input 
                                    className="mt-2" 
                                    placeholder="Enter Charge Description" 
                                    type="text" 
                                    {...field} 
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <Button type="submit" disabled={isLoading}>
                    {isLoading ? 'Adding...' : 'Add Charge'}
                </Button>
            </form>
        </Form>
    );

    const EditChargeForm: React.FC = () => (
        <Form {...editFormProps}>
            <form onSubmit={handleEditSubmit(onEditSubmit)} className="space-y-8">
                <FormField
                    control={controlEdit}
                    name="charge_type_name"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Charge Type Name</FormLabel>
                            <FormControl>
                                <Input className="mt-2" placeholder="Charge Type Name" type="text" {...field} disabled />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={controlEdit}
                    name="old_amount"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Old Charge Amount</FormLabel>
                            <FormControl>
                                <Input className="mt-2" placeholder="Old Charge Amount" type="number" {...field} disabled />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={controlEdit}
                    name="amount"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>New Charge Amount</FormLabel>
                            <FormControl>
                                <Input className="mt-2" type="number" {...field} onChange={(e) => field.onChange(parseFloat(e.target.value))} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <Button type="submit" disabled={isLoading}>
                    {isLoading ? 'Updating...' : 'Update'}
                </Button>
            </form>
        </Form>
    );

    return (
        <div className="h-screen flex overflow-hidden bg-white">
            <div className="flex flex-col w-0 flex-1 overflow-hidden">
                <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
                    <div className="py-6">
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <h1 className="text-2xl font-bold text-rails-dark-blue">Invoice #{invoiceId}</h1>
                            <h2 className="text-xs text-rails-dark-blue">Review and edit line items on this page.</h2>
                        </div>

                        <div className="mt-12 max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <div className="text-lg font-semibold text-rails-dark-blue">Invoice Level Charges</div>
                            <div className="pt-2">
                                <div>{invoiceLevelCharges && <DataTableComponentInvoiceLevelCharges />}</div>
                            </div>
                            <div className="pt-12 text-lg font-semibold text-rails-dark-blue">Order and Package Charges</div>
                            <div className="pt-2">
                                <div>{nonInvoiceLevelData && <DataTableComponent />}</div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            {isEditChargeOpen && invoiceId && selectedCharge && (
                <Dialog open={isEditChargeOpen} onOpenChange={setIsEditChargeOpen}>
                    <DialogContent>
                        <DialogHeader>
                            <DialogTitle>Edit {selectedCharge.charge_type_name} Charge</DialogTitle>
                            <DialogDescription>
                                Update {selectedCharge.charge_type_name} charge for invoice #{invoiceId}
                            </DialogDescription>
                        </DialogHeader>
                        <EditChargeForm />
                    </DialogContent>
                </Dialog>
            )}
            {openAddInvoiceLevelCharge && (
                <Dialog open={openAddInvoiceLevelCharge} onOpenChange={setOpenAddInvoiceLevelCharge}>
                    <DialogContent>
                        <DialogHeader>
                            <DialogTitle>Add Invoice Level Charge</DialogTitle>
                            <DialogDescription>Add a new invoice level charge</DialogDescription>
                        </DialogHeader>
                        <LoadingSpinner loading={isLoading} />
                        {!isLoading && <AddChargeForm isInvoiceLevel={true} />}
                    </DialogContent>
                </Dialog>
            )}
            {openAddNonInvoiceLevelCharge && selectedOrder && (
                <Dialog open={openAddNonInvoiceLevelCharge} onOpenChange={setOpenAddNonInvoiceLevelCharge}>
                    <DialogContent>
                        <DialogHeader>
                            <DialogTitle>Add Non-Invoice Level Charge</DialogTitle>
                            <DialogDescription>Add a new charge for order {selectedOrder.order_number}</DialogDescription>
                        </DialogHeader>
                        <LoadingSpinner loading={isLoading} />
                        {!isLoading && <AddChargeForm isInvoiceLevel={false} />}
                    </DialogContent>
                </Dialog>
            )}
        </div>
    );
};

export default SingleInvoiceDetail;

import React, { useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useForm } from 'react-hook-form';
import { Plus } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger, DialogDescription } from '@/components/ui/dialog';
import { Form, FormField, FormItem, FormControl, FormMessage, FormLabel } from '@/components/common/form';
import Select from 'react-select';
import { createInvoiceInvoiceAdHocInvoicesPost, getInvoiceSummaryInvoiceInvoiceSummariesGet } from '@/client/services.gen';
import { wrapperFunction } from '@/client/wrapperFunction';

interface CustomerData {
    label: string;
    value: string;
}

interface InvoiceCreateProps {
    customerData: CustomerData[];
}

const InvoiceCreate: React.FC<InvoiceCreateProps> = ({ customerData }) => {
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const customerSchema = z.object({
        customer: z.object({
            label: z.string(),
            value: z.string(),
        }),
    });

    const form = useForm<z.infer<typeof customerSchema>>({
        resolver: zodResolver(customerSchema),
    });

    const { handleSubmit, control } = form;

    const onSubmit = async (values: z.infer<typeof customerSchema>) => {
        setIsLoading(true);

        try {
            await wrapperFunction(createInvoiceInvoiceAdHocInvoicesPost, { requestBody: { customer_id: values.customer.value } }).then(() => {
                setIsLoading(false);
                window.location.reload();
            });

        } catch (error) {
            console.error('Error creating invoice:', error);
            setIsLoading(false);
        }
    };

    return (
        <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
            <DialogTrigger asChild>
                <Button
                    onClick={() => setIsDialogOpen(true)}
                    size="sm"
                    className="ml-4 h-8 text-sm"
                >
                    <Plus className="h-4 w-4 mr-2" />
                    Open Invoice
                </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>Create Invoice</DialogTitle>
                    <DialogDescription>
                    </DialogDescription>
                </DialogHeader>
                <div className="text-rails-dark-blue">
                    <Form {...form}>
                        <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
                            <FormField
                                control={control}
                                name="customer"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Customer</FormLabel>
                                        <FormControl>
                                            <Select
                                                {...field}
                                                options={customerData}
                                                placeholder="Customer Name"
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: '#DBF3D8',
                                                        primary: '#92BAA3',
                                                    },
                                                })}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <Button type="submit">{isLoading ? 'Creating' : 'Create'}</Button>
                        </form>
                    </Form>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default InvoiceCreate;
